<template>
  <div class="v_balance">
    <head-back>
        <template v-slot:returnText>
        <span>返回</span>
      </template>
    </head-back>

    <div class="v-balance-container">
      <div class="v-balance-head">
        <div class="v-balance-head-top g-flex-align-center">
          <p class="v-balance-total-title">当前总金额</p>
        </div>
        <div class="v-balance-head-bottom g-flex-align-center">
          <span class="v-balance-head-total">{{comAccount.totalBalance}}</span>
          <ul class="v-balance-head-list">
            <li class="v-balance-head-item g-flex-align-center">
              <span class="v-balance-head-item-title">本金余额</span>
              <span class="v-balance-head-item-money">{{comAccount.benJinBalance}}</span>
            </li>
            <li class="v-balance-head-item g-flex-align-center">
              <span class="v-balance-head-item-title">未返本金</span>
              <span class="v-balance-head-item-money">{{comAccount.returnBenJinBalance}}</span>
            </li>
            <li class="v-balance-head-item g-flex-align-center">
              <span class="v-balance-head-item-title">佣金余额</span>
              <span class="v-balance-head-item-money">{{comAccount.yongJinBalance}}</span>
            </li>
            <li class="v-balance-head-item g-flex-align-center">
              <span class="v-balance-head-item-title">未返佣金</span>
              <span class="v-balance-head-item-money">{{comAccount.returnYongJinBalance}}</span>
            </li>
             <li class="v-balance-head-item g-flex-align-center">
              <span class="v-balance-head-item-title">提现中</span>
              <span class="v-balance-head-item-money">{{comAccount.cashBalance}}</span>
            </li>
          </ul>
        </div>
      </div>

      <ul class="v-balance-middle v-balance-bottom">
        <li class="v-balance-bottom-item g-flex-align-center" @click="cashoutClick">
          <span>提现</span>
          <i class="iconfont icon-you"></i>
        </li>
          <li class="v-balance-bottom-item g-flex-align-center" @click="$router.push({ name: 'cashouthistory' })">
          <span>提现记录</span>
          <i class="iconfont icon-you"></i>
        </li>
      </ul>

      <ul class="v-balance-bottom">
        <li class="v-balance-bottom-item g-flex-align-center" @click="balanceItemClick('ALL')">
          <span>资金总明细</span>
          <i class="iconfont icon-you"></i>
        </li>
        <li class="v-balance-bottom-item g-flex-align-center" @click="balanceItemClick('0')">
          <span>任务本金明细</span>
          <i class="iconfont icon-you"></i>
        </li>
        <li class="v-balance-bottom-item g-flex-align-center" @click="balanceItemClick('1')">
          <span>任务佣金明细</span>
          <i class="iconfont icon-you"></i>
        </li>
        <li class="v-balance-bottom-item g-flex-align-center" @click="balanceItemClick('2')">
          <span>推广佣金明细</span>
          <i class="iconfont icon-you"></i>
        </li>
      </ul>
    </div>

    <my-center-cash-pop ref="MyCenterCashPop"/>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import MyCenterCashPop from '@/views/mycenter/MyCenterCashPop.vue'
  export default {
    components: { HeadBack, MyCenterCashPop },
    data() {
      return {
        userInfo: this.$global.userInfo
      }
    },
    methods: {
      balanceItemClick(type) {
        this.$router.push({ name: 'comdetails', params: { type }})
      },
      cashoutClick() {
        this.$refs.MyCenterCashPop.onShow()
      },
    },
    computed: {
      comAccount() {
        let benJinBalance = 0
        let returnBenJinBalance = 0
        let yongJinBalance = 0
        let returnYongJinBalance = 0
        let cashBalance = 0
        if(this.userInfo == {}) return {
          totalBalance: 0,
          benJinBalance,
          returnBenJinBalance,
          yongJinBalance,
          returnYongJinBalance,
          cashBalance
        }
        benJinBalance = this.userInfo.principal //本金余额
        returnBenJinBalance = this.userInfo.return_principal  //未返本金
        yongJinBalance = this.userInfo.commission //佣金余额
        returnYongJinBalance = this.userInfo.return_commission //未返佣金
        cashBalance = this.userInfo.cash_money //提现中
        return {
          totalBalance: Math.formatFloat(Number(benJinBalance)  + Number(returnBenJinBalance)  + Number(yongJinBalance) +  Number(returnYongJinBalance) +  Number(cashBalance), 2).toFixed(2),
          benJinBalance,
          returnBenJinBalance,
          yongJinBalance,
          returnYongJinBalance,
          cashBalance
        }
      }
    }
  }
</script>

<style lang="scss">
.v_balance {
  height: 100%;
  background: #F1F1F1;
  .v-balance-container {
    .v-balance-head {
      padding: 34px 0 26px 20px;
      background: $main_color;
      color: $white;
      .v-balance-head-top {
        .v-balance-total-title {
          padding-bottom: 8px;
          font-size: 18px;
          position: relative;
          letter-spacing: 1px;
          &::before {
            content: "";
            position: absolute;
            width: 24px;
            height: 2px;
            background: $white;
            bottom: 0;
            left: -2px;
            border-radius: 2px;
          }
        }
      }
      
      .v-balance-head-bottom {
        .v-balance-head-total {
          flex: 2;
          font-size: 28px;
          letter-spacing: 1px;
        }
        .v-balance-head-list {
          flex: 3;
          .v-balance-head-item {
            font-size: 14px;
            padding-top: 10px;
            .v-balance-head-item-money {
              font-size: 14;
              padding-left: 10px;
            }
          }
        }
      }
    }
    .v-balance-bottom {
      margin-top: 12px;
      .v-balance-bottom-item {
        padding: 18px 16px;
        background: $white;
        border-bottom: 1px solid #DCDCDC;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        span {
          flex: 1;
          font-size: 14px;
          color: #292929;
          letter-spacing: 1px;
        }
        .iconfont {
          font-size: 16px;
          color: #7D929C;
        }
      }
    }

    .v-balance-middle {
      margin-top: 0;
    }

  } 
}
</style>