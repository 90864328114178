<template>
  <div class="s_my_center_cash_pop">
    <van-popup v-model="show" position="bottom" :style="{ height: 'auto' }" >
      <p class="s-my-center-cash-title">提现方式</p>
      <p class="s-my-center-cash-tips">请选择提现方式</p>
      <i class="iconfont icon-guanbi" @click="onClose"></i> 
      <ul class="s-my-center-cash-list">
        <li class="s-my-center-cash-list-item g-flex-align-center" @click="itemClick(item)" v-for="(item, index) in cashTypeList" :key="index">
          <div class="s-cash-list-item-left g-flex-align-center">
            <i class="iconfont" :class="item.icon"></i>
            <span>{{item.title}}</span>
          </div>
          <div class="s-cash-list-item-right">
            <i class="iconfont icon-yuancircle46" v-show="item.type != activeType"></i>
            <i class="iconfont icon-xianshi_xuanzetianchong" v-show="item.type == activeType"></i>
          </div>
        </li>
      </ul>

      <div class="s-my-center-cash-btn">
        <operation-button :isPadding="false" @clickItem="emitClickItem" :buttonObj="buttonObj"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
import OperationButton from '../../components/OperationButton.vue'
export default {
  components: { OperationButton },
  data() {
    return {
      show: false,
      activeType: 1,
      buttonObj: {
        claSS: 'c-btn-default',
        title: '下一步',
        event: 'submitClick'
      },
      cashTypeList: [
        { title: '提现到银行卡', type: 1, icon: 'icon-yinhangqia' },
        { title: '提现到支付宝', type: 2, icon: 'icon-zhifubao' },
      ]
    }
  },
  methods: {
    itemClick(item) {
      this.activeType = item.type
    },
    emitClickItem(event) {
      if(!event) return
      this[event]()
    },
    submitClick() {
      let userInfo = this.$global.userInfo
      if(userInfo != {}) {
        if(this.activeType === 1) {
          if(!userInfo.bank) return this.$router.push({ name: 'changebank' })
          if(userInfo.bank.status === 0) return this.$router.push({ name: 'changebank' })
          if(userInfo.bank.status === 1) return this.$toast('银行卡账号审核中,请耐心等待')
          if(userInfo.bank.status === 2) return this.$router.push({ name: 'cashout', query: { type: this.activeType } })
        }else {
          if(!userInfo.alipay) return this.$router.push({ name: 'changeallpay' })
          if(userInfo.alipay.status === 0) return this.$router.push({ name: 'changebank' })
          if(userInfo.alipay.status === 1) return this.$toast('支付宝账号审核中,请耐心等待')
          if(userInfo.alipay.status === 2) return this.$router.push({ name: 'cashout', query: { type: this.activeType }  })
        }
      } 
      console.log('确定')
    },
    onShow() {
      this.show = true
    },
    onClose() {
      this.show = false
    }
  }
}
</script>

<style lang='scss'>
.s_my_center_cash_pop {
  .van-popup {
    padding: 15px 20px;
    .s-my-center-cash-title {
      color: #9C9C9C;
      font-size: 16px;
    }
    .s-my-center-cash-tips {
      font-size: 12px;
      color: #9C9C9C;
      padding-top: 10px;
    }
    .icon-guanbi {
      font-size: 20px;
      color: #B0B0B0;
      position: absolute;
      right: 5px;
      top: 5px;
      padding: 5px;
    }
    .s-my-center-cash-list {
      padding-top: 10px;
      padding-bottom: 20px;
      .s-my-center-cash-list-item {
        padding: 10px 0;
        border-bottom: 1px solid #eceaea;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        .s-cash-list-item-left {
          flex: 1;
          .iconfont {
            font-size: 24px;
            color: $main_color;
            &.icon-yinhangqia {
              color: #FB560A;
            }
            &.icon-zhifubao {
              color: $blue;
            }
          }
          span {
            color: $black;
            font-size: 14px;
            padding-left: 10px;
          }
        }
        .s-cash-list-item-right {
          .iconfont {
            font-size: 26px;
            &.icon-yuancircle46 {
              color: #E4E4E4;
            }
            &.icon-xianshi_xuanzetianchong {
              color: $main_color;
            }
          }
        }
      }
    }
  }
}
</style>